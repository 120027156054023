import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import scroll from 'vue-seamless-scroll'
import VScaleScreen from 'v-scale-screen'
import dataV from '@jiaminghi/data-view'

Vue.config.productionTip = false
Vue.use(ElementUI).use(scroll)
Vue.use(VScaleScreen)
Vue.use(dataV)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
