<template>
  <VueCountUp
    class="unitSmall"
    :start-value="startVal"
    :end-value="currentVal"
    :options="options"
    :duration="6"
  ></VueCountUp>
</template>

<script>
import VueCountUp from 'vue-countupjs'

export default {
  components: { VueCountUp },
  name: 'countTo',
  props: {
    initVal: {
      type: Number
    },
    endVal: {
      type: Number
    }
  },
  data: function () {
    return {
      startVal: this.initVal,
      currentVal: this.endVal,
      options: {
        useEasing: true, // 使用缓和
        useGrouping: false, // 使用分组(是否显示千位分隔符,一般为 true)
        separator: '', // 分隔器(千位分隔符,默认为',')
        decimal: '.', // 十进制(小数点符号,默认为 '.')
        prefix: '', // 字首(数字的前缀,根据需要可设为 $,¥,￥ 等)
        suffix: '' // 后缀(数字的后缀 ,根据需要可设为 元,个,美元 等)
      }
    }
  },
  watch: {
    endVal: function (val) {
      this.startVal = this.startVal === undefined ? val : this.currentVal
      this.currentVal = val
    }
  }
}
</script>
